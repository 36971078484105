aside {
  background-color: rgb(35, 125, 7) !important;
}

.ant-select-dropdown {
  min-width: 240px;
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imagecontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: "40%";
  background: "red";
}
.imageBox {
  position: relative;
}
.imgremBtn {
  position: absolute;
  top: 0;
  right: 1vh;
  height: 4vh;
  width: 4vh;
  background-color: #ff9999;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.error_class {
  border-color: #ff9999;
  background: #ffe6e6;
}

.loadingMain {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* =================login form======================== */
.loginCont {
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.loginMain {
  width: 40%;
  height: 60%;
  background-color: white;
  margin: auto;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginPage {
  width: 60%;
}
.loginPage h2 {
  text-align: center;
  margin: 10px;
}
.loginPage dt {
  margin-bottom: 10px;
}
.loginPage .input {
  width: 80%;
  padding: 10px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #9cd129;
}
.loginBtn {
  margin-top: 10%;
}
.loginBtn button {
  padding: 2%;
  width: 40%;
  border: none;
  background: #9cd129;
  color: white;
  border-radius: 10px;
  outline: transparent;
}
.loginBtn button:hover {
  background-color: black;
}

/*================================= Add Drugs ====================*/
.AddContainer {
  background: #f4f6f9;
  padding: 5px;
}
.AddContainer h2 {
  margin-bottom: 40px;
  margin-left: 20px;
}

/*============================== Add products======================= */

.productContainer {
  background: #f4f6f9;
  padding: 5px;
}
.productContainer h2 {
  margin-bottom: 40px;
  margin-left: 20px;
}

.checkbocProduct {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.checkbocProduct input {
  margin: 10px;
}
.categorytag {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.categorytag input {
  margin: 10px;
  padding: 2px;
}
.errorCont {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* ==========================nav hover========================== */
nav ul li p {
  font-size: 16px;
  font-weight: 500;
}
.nav-link span {
  font-size: 20px;
  margin-right: 5px;
}
.arrowsize {
  margin-top: 7px !important;
}
.brand-text {
  font-size: 22px;
  font-weight: 600 !important;
}
.headerDesign {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  height: 100%;
}
.headerDesign li {
  list-style: none;
  margin-right: 20px;
}
.headerDesign li span {
  font-size: 25px;
  cursor: pointer;
  color: #051937;
}
.productDesign {
  margin-left: 15px;
}
.kjhTags {
  margin-left: 10px;
}
.errorhandle {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.errorlabel {
  color: tomato;
  margin-top: -5px;
  font-size: 12px;
}
.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1 {
  width: 100%;
  background: rgba(0, 0, 0, 0);
}
.box2 {
  width: 100%;
  background: rgba(0, 0, 0, 0);
}
.aguserid {
  background: #00bf72;
  height: 7vh;
  border-radius: 4px;
}
.ageidbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #f4f6f9;
}
.agedivigm {
  margin-left: 35%;
  text-align: center;
}
.agedivigm .agentimg {
  width: 30vh;
  background: #f8f9fa;
  height: 30vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.agentimg img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

/* ============================ media query =========================*/
@media screen and (max-width: 1400px) {
  .loginPage {
    width: 75%;
  }
  .loginMain {
    width: 45%;
  }
}
@media screen and (max-width: 1200px) {
  .loginPage {
    width: 70%;
  }
  .loginMain {
    width: 42%;
  }
}

@media screen and (max-width: 1000px) {
  .loginPage {
    width: 70%;
  }
  .loginMain {
    width: 55%;
  }
}
@media screen and (max-width: 800px) {
  .loginPage {
    width: 85%;
  }
  .loginMain {
    width: 65%;
  }
  .categoryMain .categoryMain1 {
    width: 95%;
  }
  .categoryMain .categoryMain2 {
    width: 95%;
  }
}
@media screen and (max-width: 500px) {
  .loginPage {
    width: 90%;
  }
  .loginMain {
    width: 90%;
  }
  .categoryMain .categoryMain1 {
    width: 95%;
  }
  .categoryMain .categoryMain2 {
    width: 95%;
  }
  .ageinfo {
    flex-direction: column;
  }
}
@media screen and (max-width: 350px) {
  .loginPage {
    width: 80%;
  }
  .loginMain {
    width: 90%;
  }
  .ageinfo {
    flex-direction: column;
  }
}
